class AutoComplete extends HTMLElement {
  connectedCallback() {
    const userInputText =
      !!this.dataset?.userInputText && this.dataset.userInputText == "true"
    document.addEventListener("DOMContentLoaded", () => {
      const $jsSelect2 = $(".js-select2")
      $jsSelect2.select2({
        width: "100%", // jQueryのhide・showを使った際に、width:autoだと幅が狭くなってしまうため
        minimumInputLength: 1,
        ajax: {
          cache: true,
          delay: 300,
          url: this.dataset.url,
          dataType: "json",
          data: (params) => {
            return {
              q: params.term,
            }
          },
          processResults: (items, params) => {
            const results = items.map((item) => {
              return {
                id: item.id ? item.id : item.name,
                text: item.name,
                fetchedData: true,
              }
            })
            return {
              results: results,
            }
          },
        },
        tags: userInputText,
        language: {
          inputTooShort: function (t) {
            return `1文字以上入力してください。`
          },
          noResults: function () {
            return "該当するものが見つかりません。"
          },
        },
        sorter: function (results) {
          if (userInputText && !results[0]?.fetchedData) {
            // results配列の先頭にユーザーが入力した文字列データが保持されている場合は、末尾に移動する
            const userInputText = results.shift()
            return [...results, userInputText]
          }
          return results
        },
      })

      $(this).on("select2:open", (e) => {
        document
          .querySelector(".select2-container--open .select2-search__field")
          .focus()
      })
    })
  }
}
customElements.define("om-auto-complete", AutoComplete)

import { dom, library } from "@fortawesome/fontawesome-svg-core"
import {
  faFacebookF,
  faGoogle,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import {
  faClock,
  faComment,
  faEnvelope,
  faStar as farFaStar,
} from "@fortawesome/free-regular-svg-icons"
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faBars,
  faChartLine,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faCommentDots,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faGraduationCap,
  faHashtag,
  faHome,
  faLock,
  faPlus,
  faSearch,
  faSignOutAlt,
  faStar,
  faStarHalfAlt,
  faTimes,
  faUserAlt,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faBars,
  faChartLine,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faClock,
  faComment,
  faCommentDots,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExternalLinkAlt,
  faFacebookF,
  faGoogle,
  faGraduationCap,
  faHashtag,
  faHome,
  faLock,
  faPlus,
  farFaStar,
  faSearch,
  faSignOutAlt,
  faStar,
  faStarHalfAlt,
  faTimes,
  faTwitter,
  faUserAlt,
  faUserCog
)
dom.watch()
